<template>
  <b10-base>
    <b10-list
      :title="title"
      :page-store-name="pageStoreName"
      :items="items"
      :filter="filter"
      :search="search"
      :sorter="sorter"
      :sorter-desc="sorterDesc"
      :show-load-more="showLoadMore"
      :scroll-position="scrollPosition"
      empty-message="No se encontraron artículos"
      wrapper-component="v-list"
      :wrapper-component-props="{ 'three-line': true }"
      :loading="loadingData"
      :showing-filter.sync="showingDialogs.filter"
      :showing-sorter.sync="showingDialogs.sorter"
      :toolbar-options="toolbarOptions"
      :init-filter="initFilter"
      :load="loadItems"
      :reload-dirty-items="reloadDirtyItems"
      :page-loaded="pageLoaded"
      pk-name="articulo.idarticulo"
      @click-item="clickListItem"
      @click-toolbar-option="clickToolbarOption"
      @open-filter="openFilter"
    >
      <template
        #listItem="slotProps"
      >
        <articulo-list-item
          :item="slotProps.item"
          :has-perm-articulos-ver-tarifas="hasViewPerm(permissions.articulos.verTarifas)"
          :mostrar-con-impuestos="mostrarConImpuestos"
        />
      </template>
    </b10-list>
    <articulo-list-legend
      :showing.sync="showingDialogs.legends"
    />
  </b10-base>
</template>

<script>
import _ from '@/utils/lodash'
import { listPageMixin } from '@/mixins/listPageMixin'
import Data from './ArticuloListData'
import ArticuloListItem from './components/ArticuloListItem'
import ArticuloListLegend from './components/ArticuloListLegend'
import { get, sync } from 'vuex-pathify'
import { PARAMETRO } from '@/utils/consts'
import { NextRouteNavigate } from '@/router'

const pageStoreName = 'pagesOfflineArticuloList'

export default {
  components: {
    ArticuloListItem, ArticuloListLegend,
  },
  mixins: [listPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      pageStoreName,
      toolbarOptions: {
        mostrarOcultarImpuestos: {
          title: 'Mostrar sin impuestos',
          visible: true,
          icon: 'show',
        },
        legend: {
          title: 'Colores y leyendas',
          visible: true,
          icon: 'info',
        },
      },
      showingDialogs: {
        legends: false,
      },
    }
  },
  computed: {
    items: get(`${pageStoreName}/items`),
    filter: get(`${pageStoreName}/filter`),
    search: get(`${pageStoreName}/search`),
    sorter: get(`${pageStoreName}/sorter`),
    sorterDesc: get(`${pageStoreName}/sorterDesc`),
    currentPage: get(`${pageStoreName}/currentPage`),
    showLoadMore: get(`${pageStoreName}/showLoadMore`),
    scrollPosition: get(`${pageStoreName}/scrollPosition`),
    nextRoute: get(`${pageStoreName}/nextRoute`),
    mostrarConImpuestos: sync(`${pageStoreName}/mostrarConImpuestos`),
  },
  async created () {
    // routeQuery.idttarifa
    // routeQuery.idbanco_precio
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = 'Artículos'
      this.updateMostrarOcultarImpuestos()
      this.pageLoaded = true
    },
    async initFilter () {
      if (
        this.nextRoute &&
        // no tiene sentido filtrar por "mi stock" al agregar material afectado
        // es material que se encuentra ya instalado pero no esta registrado en el subsistema
        this.nextRoute.name !== 'offline__lparte-trabajo-material-afectado-add'
      ) {
        const filtrarStockMiAlmacen = (
          await this.$offline.parametro.valorParametro(PARAMETRO.parametros.FILTRAR_ARTICULOS_POR_STOCK_TECNICO)
        ) === '1'
        if (filtrarStockMiAlmacen) {
          await this.setStoreProperty('filter@stockMiAlmacen.value', true)
        }
      }
      await this.setStoreProperty('filter@frecuente.value', true)
    },
    async loadItems () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectArticulo(
          this,
          this.filter,
          this.search,
          this.sorter,
          this.currentPage,
          this.routeQuery.idttarifa,
          this.routeQuery.idbanco_precio
        )
        await this.loadItemsBase(dataset, metadata)
      } finally {
        this.loadingData = false
      }
    },
    async reloadDirtyItems () {
      const dirty = await this.$dirty.popDirty(this.$route.name)
      this.loadingData = true
      try {
        await this.reloadItemsBase(
          await Data.selectArticuloRows(
            this,
            dirty.modified,
            this.routeQuery.idttarifa,
            this.routeQuery.idbanco_precio
          ),
          dirty,
          'articulo.idarticulo'
        )
      } finally {
        this.loadingData = false
      }
    },
    async clickListItem (item) {
      if (this.nextRoute) {
        if (_.has(this.nextRoute.params, 'idarticulo')) {
          await this.setStoreProperty('nextRoute@params.idarticulo', item.data.articulo.idarticulo)
          NextRouteNavigate(this.nextRoute)
        }
      }
    },
    clickToolbarOption (option) {
      if (option === this.toolbarOptions.legend) {
        this.showingDialogs.legends = true
      } else if (option === this.toolbarOptions.mostrarOcultarImpuestos) {
        if (this.mostrarConImpuestos) {
          this.mostrarConImpuestos = false
        } else {
          this.mostrarConImpuestos = true
        }
        this.updateMostrarOcultarImpuestos()
      }
    },
    updateMostrarOcultarImpuestos () {
      if (this.mostrarConImpuestos) {
        this.toolbarOptions.mostrarOcultarImpuestos.title = 'Mostrar sin impuestos'
        this.toolbarOptions.mostrarOcultarImpuestos.icon = 'hide'
      } else {
        this.toolbarOptions.mostrarOcultarImpuestos.title = 'Mostrar con impuestos'
        this.toolbarOptions.mostrarOcultarImpuestos.icon = 'show'
      }
    },
    async openFilter () {
      await this.setStoreProperty('filter@marca.items', await Data.selectMarca(this))
      await this.setStoreProperty('filter@subfamilia.items', await Data.selectSubfamilia(this))
    },
  }
}
</script>
