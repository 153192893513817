<template>
  <b10-list-item
    :item="formattedItem"
  >
    <v-list-item-content>
      <v-list-item-title
        v-html="formattedItem.title"
      />
      <v-list-item-subtitle
        v-html="formattedItem.subtitle"
      />
      <v-list-item-subtitle>
        <v-chip
          v-if="hasPermArticulosVerTarifas"
          label
          :color="formattedItem.precio_color"
          dark
          small
        >
          <div v-if="mostrarConImpuestos">
            {{ formattedItem.precio_con_iva_formateado }}
          </div>
          <div v-else>
            {{ formattedItem.precio_formateado }}
          </div>
        </v-chip>
        <v-chip
          v-if="formattedItem.articulo.frecuente"
          label
          color="info"
          dark
          small
          class="ml-1"
        >
          F
        </v-chip>
        <v-chip
          v-if="formattedItem.articulo.estado === ARTICULO.estados.obsoleto"
          label
          color="warning"
          dark
          small
          class="ml-1"
        >
          Obsoleto
        </v-chip>
      </v-list-item-subtitle>
    </v-list-item-content>
  </b10-list-item>
</template>

<script>
import _ from '@/utils/lodash'
import { nonEmpty } from '@/utils/templates'
import { ARTICULO } from '@/utils/consts'
import colors from 'vuetify/lib/util/colors'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    hasPermArticulosVerTarifas: {
      type: Boolean,
      default: false,
    },
    mostrarConImpuestos:{
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      ARTICULO,
    }
  },
  computed: {
    formattedItem () {
      const item = _.cloneDeep(this.item)
      item.title = item.articulo.descripcion
      item.subtitle = this.$options.filters.linebreaksBr(nonEmpty`
        ${item.articulo.codigo} ${item.articulo.codpro}`)
      if (item.precio && item.precio_con_iva) {
        item.precio_formateado = `${this.$options.filters.currency(item.precio)} (imp. no incl.)`
        item.precio_con_iva_formateado = this.$options.filters.currency(item.precio_con_iva)
        item.precio_color = colors.green.base
      } else {
        item.precio_formateado = 'Sin tarifa'
        item.precio_con_iva_formateado = 'Sin tarifa'
        item.precio_color = colors.red.base
      }
      return item
    }
  }
}
</script>
